<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('user.users') }}</h2>
    </div>
    <div class="filter-container">
      <el-row>
        <el-col :span="4" style="margin: 10px;">
          <vs-card class="success-card" @click="havingRole('admin')">
            <template #title>
              <h3>Admin</h3>
            </template>
            <template #text>
              <h2><count-to :start-val="0" :end-val="adminsCount" :duration="3000" class="card-panel-num" /></h2>
            </template>
          </vs-card>
        </el-col>
        <el-col :span="6" style="margin: 10px;">
          <vs-card class="warning-card" @click="havingRole('controleur')">
            <template #title>
              <h3>Contrôleurs internes</h3>
            </template>
            <template #text>
              <h2><count-to :start-val="0" :end-val="controleursCount" :duration="3000" class="card-panel-num" /></h2>
            </template>
          </vs-card>
        </el-col>
        <el-col :span="4" style="margin: 10px;">
          <vs-card class="secondary-card" @click="excludeRole('admin,controleur')">
            <template #title>
              <h3>Autres</h3>
            </template>
            <template #text>
              <h2><count-to :start-val="0" :end-val="othersCount" :duration="3000" class="card-panel-num" /></h2>
            </template>
          </vs-card>
        </el-col>
      </el-row>
    </div>
    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilter" />
        <el-select v-model="query.role" :placeholder="$t('general.role')" clearable class="mr-15 filter-item" @change="handleFilter">
          <el-option v-for="(role, index) in roles" :key="index" :label="role.name | uppercaseFirst" :value="role.id" />
        </el-select>
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
          {{ $t('general.search') }}
        </el-button>
        <el-button class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handleCreate">
          {{ $t('general.add') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
      </div>

      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="ID" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.index }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('user.nom')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.nom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('user.prenom')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.prenom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('user.email')" min-width="100px">
          <template slot-scope="scope">
            <span>{{ scope.row.email }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('user.role')" width="120px">
          <template slot-scope="scope">
            <span>{{ scope.row.roles.join(', ') | uppercaseFirst }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="checkRole(['admin'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setUserStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.actions')" width="150">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.roles.includes('admin') && checkPermission(['modifier utilisateur'])" type="primary" size="small" icon="el-icon-edit" @click="handleEditPermissions(scope.row.id);" />
            <el-button v-if="!scope.row.roles.includes('admin') && checkPermission(['supprimer utilisateur'])" type="danger" size="small" icon="el-icon-delete" @click="handleDelete(scope.row.id, scope.row.nom)" />
            <!-- el-popconfirm
              v-if="!scope.row.roles.includes('admin')"
              :confirm-button-text="$t('general.deleteConfirmButtonText')"
              :cancel-button-text="$t('general.deleteCancelButtonText')"
              icon="el-icon-info"
              icon-color="red"
              :title="$t('general.deleteWarningText')"
              @confirm="handleDelete(scope.row.id, scope.row.nom);"
            >
              <el-button slot="reference" type="danger" size="small" icon="el-icon-delete" />
            </el-popconfirm-->
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getList" />

      <!-- ======= CREER USER DRAWER ======= -->
      <el-drawer
        ref="RoleCreationDrawer"
        :title="editing ? $t('user.edit') + ' ' + currentUser.name : $t('user.AddUser')"
        :visible.sync="dialogCreateUser"
        :before-close="handleCloseUserCreateDraw"
        :loading="userCreating"
        size="40%"
      >
        <div class="drawer-body">
          <div class="user-create-form content-inputs">
            <el-form ref="userForm" :rules="rules" :model="currentUser" label-position="left">
              <el-form-item :label="$t('user.role')" prop="role">
                <el-select v-model="currentUser.role" class="filter-item" :placeholder="$t('user.selectRole')" style="width: 100%;" @change="onUserRoleSelectChange">
                  <el-option v-for="(role, idex) in nonAdminRoles" :key="idex" :label="role.name | uppercaseFirst" :value="role.name" />
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('user.nom')" prop="nom">
                <el-input v-model="currentUser.nom" />
              </el-form-item>
              <el-form-item :label="$t('user.prenom')" prop="prenom">
                <el-input v-model="currentUser.prenom" />
              </el-form-item>
              <el-form-item :label="$t('user.email')" prop="email">
                <el-input v-model="currentUser.email" />
              </el-form-item>
              <el-form-item :label="$t('user.username')" prop="username">
                <el-input v-model="currentUser.username" />
              </el-form-item>
              <el-form-item>
                <div v-if="editing" class="edit-credentials">
                  <el-button size="mini" icon="el-icon-edit" style="margin-left:10px;margin-top:10px;" @click="editCredentials = !editCredentials">{{ editCredentials ? $t('user.nochangeCredentils') : $t('user.changeCredentils') }}</el-button>
                </div>
              </el-form-item>
              <div v-if="!editing || editCredentials" class="credentials-input">
                <el-form-item :label="$t('user.password')" prop="password">
                  <el-input v-model="currentUser.password" show-password />
                </el-form-item>
                <el-form-item :label="$t('user.c_password')" prop="c_password">
                  <el-input v-model="currentUser.c_password" show-password />
                </el-form-item>
              </div>
            </el-form>
            <el-form label-width="80px" label-position="top">
              <el-form-item :label="$t('permission.addedPermissions')">
                <div v-if="isEmptyArray(checkedPermissionsId)" class="no-selection">
                  <el-empty :description="$t('permission.noSelectionAdd')" />
                </div>
                <el-tag
                  v-for="(pID, index) in checkedPermissionsId"
                  :key="index"
                  :closable="!isPermissionHerited(pID)"
                  :type="isPermissionHerited(pID) ? 'info' : getSelectedPermissionName(pID).type"
                  style="margin:5px;"
                  @close="unCheckPermission(pID)"
                >
                  {{ getSelectedPermissionName(pID).name | uppercaseFirst }}
                </el-tag>
              </el-form-item>
            </el-form>
          </div>
          <div class="permissionscontainer">
            <el-card class="box-card">
              <el-form :model="currentUser" label-width="80px" label-position="top">
                <div class="row search-input">
                  <el-select v-model="selectedPermissionNames" multiple filterable placeholder="Sélectionner" @change="showOnlySelected">
                    <el-option
                      v-for="(permissionName, index) in normalizedPermissionNames"
                      :key="index"
                      :label="permissionName"
                      :value="permissionName"
                      style="width:100%"
                    />
                  </el-select>
                </div>
                <el-collapse v-model="activeName" accordion>
                  <el-collapse-item v-for="(normalizedPermission,index) in normalizedPermissions" :key="index" :title="normalizedPermission.name" :name="index">
                    <el-checkbox-group v-model="checkedPermissionsId">
                      <el-checkbox
                        v-for="(thePermission,key) in normalizedPermission.permissions"
                        :key="key"
                        :label="thePermission.id"
                        :class="thePermission.name.toLowerCase()+'-pane'"
                        :disabled="isPermissionHerited(thePermission.id)"
                        @change="handleCheckedPermissionChange(thePermission.name.toLowerCase(), normalizedPermission.permissionsIds)"
                      >
                        {{ thePermission.name | uppercaseFirst }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-collapse-item>
                </el-collapse>
              </el-form>
            </el-card>
            <div class="clear-left" />
          </div>
        </div>
        <div class="el-drawer__footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="cancelRoleForm">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="userCreating" @click="editing ? updateUser() : createUser()">
              {{ userCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </div>

      </el-drawer>
      <!-- ======= FIN CREER USER DRAWER ======= -->

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import UserResource from '@/api/user';
import RoleResource from '@/api/role';
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import checkPermission from '@/utils/permission'; // Vérification Permission
import CountTo from 'vue-count-to';
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const userResource = new UserResource();
const permissionResource = new Resource('permissions');
const roleResource = new RoleResource();
export default {
  name: 'UserList',
  components: { Pagination, CountTo },
  directives: { waves, permission },
  data() {
    var validatec_password = (rule, value, callback) => {
      if (value !== this.currentUser.password) {
        callback(new Error(this.$t('user.PasswordsInconform')));
      } else {
        callback();
      }
    };
    return {
      adminsCount: 0,
      controleursCount: 0,
      othersCount: 0,
      editCredentials: false,
      editing: false,
      activeName: '',
      list: null,
      total: 0,
      loading: true,
      downloading: false,
      userCreating: false,
      permissions: [],
      checkedPermissionsId: [],
      filtredPermissions: [],
      normalizedPermissionNames: [],
      normalizedPermissions: [],
      allnormalizedPermissions: [],
      selectedPermissionNames: [],
      permissionsHeritedFromRole: [],
      query: {
        page: 1,
        limit: 100,
        keyword: '',
        role: '',
      },
      roles: [],
      nonAdminRoles: [],
      currentUser: {},
      currentUserNoHeritedPermissions: [],
      /* currentUser: {
        nom: '',
        permissions: [],
        rolePermissions: [],
      }, */
      dialogCreateUser: false,
      dialogPermissionVisible: false,
      dialogPermissionLoading: false,
      currentUserId: 0,
      rules: {
        role: [{ required: true, message: this.$t('user.RoleRequired'), trigger: 'change' }],
        nom: [{ required: true, message: this.$t('user.NomRequired'), trigger: 'blur' }],
        email: [
          { required: true, message: this.$t('user.EmailRequired'), trigger: 'blur' },
          { type: 'email', message: this.$t('user.EmailInvalide'), trigger: ['blur', 'change'] },
        ],
        password: [{ required: true, message: this.$t('user.PasswordRequired'), trigger: 'blur' }, { min: 6, message: this.$t('user.PasswordMin6'), trigger: 'blur' }],
        c_password: [{ validator: validatec_password, trigger: 'blur' }],
      },
      permissionProps: {
        children: 'children',
        label: 'name',
        disabled: 'disabled',
      },
      menuPermissions: [],
      otherPermissions: [],
    };
  },
  computed: {
  },
  created() {
    this.resetNewUser();
    this.getRoles();
    this.getList();
    if (checkPermission(['gerer permission'])) {
      this.getPermissions();
    }
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    unCheckPermission(id){
      console.log(id);
      const index = this.checkedPermissionsId.indexOf(id);
      this.checkedPermissionsId.splice(index, 1);
    },
    async setUserStatus(id, type){
      const data = await userResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.getList();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleCheckAllPermissionsChange(currentPermissions) {
      if (currentPermissions.every((val) => this.checkedPermissionsId.includes(val))){
        this.checkedPermissionsId = this.checkedPermissionsId.concat(currentPermissions);
      } else {
        this.checkedPermissionsId = this.checkedPermissionsId.filter((el) => !currentPermissions.includes(el));
      }
      this.isIndeterminate = false;
    },
    handleCheckedPermissionChange(type, currentPermissions) {
      if (type === 'gerer'){
        if (!currentPermissions.every((val) => this.checkedPermissionsId.includes(val))){
          this.checkedPermissionsId = [...new Set([...this.checkedPermissionsId, ...currentPermissions])];
        } else {
          this.checkedPermissionsId = this.checkedPermissionsId.filter((el) => !currentPermissions.includes(el));
        }
      }
      this.checkedPermissionsId = [...new Set([...this.checkedPermissionsId, ...this.permissionsHeritedFromRole])];
    },
    filterNode(value, data) {
      if (!value) {
        return true;
      }
      return data.name.indexOf(value) !== -1;
    },
    handleCloseRoleDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    handleCloseUserCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelRoleForm() {
      this.$refs.RoleCreationDrawer.closeDrawer();
    },
    resetNewRole() {
      this.editing = false;
      this.newRole = {
        name: '',
      };
    },
    async getRoles() {
      this.loading = true;
      const { data } = await roleResource.list(this.query);
      this.roles = data;
      this.nonAdminRoles = [];
      this.roles.forEach(role => {
        if (role.name.toLowerCase() !== 'admin') {
          this.nonAdminRoles.push(role);
        }
      });
      this.loading = false;
      this.setSta();
    },
    getRoleUserCount(roleName){
      const found = this.roles.find(role => role.name === roleName);
      if (found) {
        return found.userscount;
      } else {
        return 0;
      }
    },
    onUserRoleSelectChange(){
      this.checkedPermissionsId = [];
      this.permissionsHeritedFromRole = [];
      const rolePermissionsID = [];
      // const found = this.roles.find(role => role.id === id);
      const found = this.roles.find(role => role.name === this.currentUser.role);
      const normalizedNames = [];
      found.permissions.forEach(permission => {
        rolePermissionsID.push(permission.id);
        const normalizedName = this.normalizeLabel(permission.name);
        if (normalizedName != null && !normalizedNames.includes(normalizedName)){
          normalizedNames.push(normalizedName);
        }
      });
      this.normalizedPermissions = this.allnormalizedPermissions;
      this.selectedPermissionNames = normalizedNames;
      this.permissionsHeritedFromRole = rolePermissionsID;
      this.checkedPermissionsId = [...new Set([...this.currentUserNoHeritedPermissions, ...this.permissionsHeritedFromRole])];
      this.showOnlySelected();
    },

    isPermissionHerited(permissionID){
      return this.permissionsHeritedFromRole.includes(permissionID);
    },

    getPermissionColor(permissionName){
      if (permissionName.startsWith('creer')) {
        return 'primary';
      } else if (permissionName.startsWith('modifier')) {
        return 'warning';
      } else if (permissionName.startsWith('supprimer')) {
        return 'danger';
      } else if (permissionName.startsWith('gerer')) {
        return 'success';
      } else if (permissionName.startsWith('activer')) {
        return 'primary';
      } else if (permissionName.startsWith('visible')) {
        return 'info';
      } else {
        return 'primary';
      }
    },
    getSelectedPermissionName(permissionID){
      const foundPermission = this.permissions.find(permission => permission.id === permissionID);
      return { name: foundPermission.name, type: this.getPermissionColor(foundPermission.name) };
    },
    showOnlySelected(){
      const selected = [];
      this.selectedPermissionNames.forEach(permissionName => {
        const foundPermission = this.allnormalizedPermissions.find(permission => permission.name === permissionName);
        selected.push(foundPermission);
      });
      if (!this.isEmptyArray(selected)) {
        this.normalizedPermissions = selected;
      } else {
        this.normalizedPermissions = this.allnormalizedPermissions;
      }
    },
    async getPermissions() {
      this.normalizedPermissions = [];
      this.permissionLabels = [];
      const { data } = await permissionResource.list({});
      const all = data;
      this.permissions = all;
      all.forEach(permission => {
        const normalizedName = this.normalizeLabel(permission.name);
        if (normalizedName != null && !this.normalizedPermissionNames.includes(normalizedName)){
          this.normalizedPermissionNames.push(normalizedName);
        }
        this.permissionLabels.push(permission.name);
      });

      this.normalizedPermissionNames.forEach(permissionName => {
        var element = { name: permissionName, permissions: [], permissionsIds: [] };
        all.forEach(permission => {
          if (permission.name.toLowerCase().includes(permissionName.toLowerCase())) {
            element.name = permissionName;
            element.permissions.push({ id: permission.id, name: permission.name.toLowerCase().replace(' ' + permissionName.toLowerCase(), '') });
            element.permissionsIds.push(permission.id);
          }
        });
        this.normalizedPermissions.push(element);
        this.allnormalizedPermissions = this.normalizedPermissions;
      });
    },
    checkTakenPermission(name){
      return this.permissionLabels.includes(name);
    },
    checkTakenPermissionName(name){
      if (name == null) {
        return true;
      }
      return this.normalizedPermissionNames.includes(this.$options.filters.uppercaseFirst(name.toLowerCase()));
    },
    normalizeLabel(name) {
      var normalizedName;
      if (name.startsWith('creer')) {
        normalizedName = name.substring(6);
      } else if (name.startsWith('modifier')) {
        normalizedName = name.substring(9);
      } else if (name.startsWith('supprimer')) {
        normalizedName = name.substring(10);
      } else if (name.startsWith('gerer')) {
        normalizedName = name.substring(6);
      } else if (name.startsWith('activer')) {
        normalizedName = name.substring(8);
      } else if (name.startsWith('visible')) {
        normalizedName = name.substring(8);
      } else {
        normalizedName = null;
      }
      return this.$options.filters.uppercaseFirst(normalizedName);
    },
    permissionsStandard(permissions) {
      const permissionsStandards = [];
      permissions.forEach(permission => {
        const permissionName = permission.name;
        if (permissionName.startsWith('creer') || permissionName.startsWith('modifier') || permissionName.startsWith('supprimer') || permissionName.startsWith('gerer') || permissionName.startsWith('activer') || permissionName.startsWith('visible')) {
          permissionsStandards.push(permission);
        }
      });
      return permissionsStandards;
    },

    handleDeleteRole(id) {
      roleResource.destroy(id).then(response => {
        this.$message({
          type: 'success',
          message: this.$t('roles.suppressionEffectue'),
        });
        this.handleFilter();
      }).catch(error => {
        console.log(error);
      });
    },

    handleDeletePermission(id) {
      this.permissionTableLoading = true;
      permissionResource.destroy(id).then(response => {
        this.$message({
          type: 'success',
          message: this.$t('permission.suppressionEffectue'),
        });
        this.getPermissions();
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.permissionTableLoading = false;
      });
    },
    setSta(){
      var allUsers = 0;
      this.roles.forEach(role => {
        allUsers += role.userscount;
      });
      this.adminsCount = this.getRoleUserCount('admin');
      this.controleursCount = this.getRoleUserCount('controleur');
      this.othersCount = allUsers - (this.adminsCount + this.controleursCount);
    },
    havingRole(roleName){
      delete this.query.excludesroles;
      const found = this.roles.find(role => role.name === roleName);
      this.query.role = found.id;
      this.handleFilter();
    },
    excludeRole(roles){
      this.query.role = null;
      const rolestoexclude = roles.split(',');
      this.query.excludesroles = rolestoexclude;
      this.handleFilter();
    },
    async getList() {
      const { limit, page } = this.query;
      console.log('the current query is', this.query, { limit, page });
      this.loading = true;
      const { data, meta } = await userResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      console.log('DATATATA USERS : ', this.list);
      this.loading = false;
      this.total = meta.total;
      this.getRoles();
    },
    handleFilter() {
      this.query.page = 1;
      this.getList();
    },
    handleCreate() {
      this.normalizedPermissions = this.allnormalizedPermissions;
      this.selectedPermissionNames = [];
      this.checkedPermissionsId = [];
      this.permissionsHeritedFromRole = [];
      this.currentUserNoHeritedPermissions = [];
      this.editing = false;
      this.resetNewUser();
      this.dialogCreateUser = true;
      this.$nextTick(() => {
        this.$refs['userForm'].clearValidate();
      });
    },
    handleDelete(id, name) {
      this.$confirm(this.$t('user.deleteWarn') + '. ' + name + ' ' + this.$t('user.deleteContinue'), 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: this.$t('user.cancel'),
        type: 'warning',
      }).then(() => {
        this.$swal({
          title: this.$t('general.SureQuestion'),
          text: this.$t('general.irrversibleMessage'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('general.ouiSupprimer'),
        }).then((result) => {
          if (result.isConfirmed) {
            userResource.destroy(id).then(response => {
              this.$message({
                type: 'success',
                message: this.$t('user.suppressionEffectue'),
              });
              this.handleFilter();
            }).catch(error => {
              console.log(error);
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('user.suppressionAnnule'),
        });
      });
    },
    async handleEditPermissions(id) {
      this.loading = true;
      this.checkedPermissionsId = [];
      this.permissionsHeritedFromRole = [];
      this.editing = true;
      this.currentUserId = id;
      const found = this.list.find(user => user.id === id);
      const { data } = await userResource.permissions(id);
      this.currentUser = {
        id: found.id,
        nom: found.nom,
        prenom: found.prenom,
        email: found.email,
        username: found.username,
        role: found.roles[0],
        permissions: data,
      };
      const fromRolePermissionsIds = [];
      const userPermissionsIds = [];
      data.role.forEach(permission => {
        if (!fromRolePermissionsIds.includes(permission.id)) {
          fromRolePermissionsIds.push(permission.id);
        }
      });
      data.user.forEach(permission => {
        if (!userPermissionsIds.includes(permission.id)) {
          userPermissionsIds.push(permission.id);
        }
      });

      const normalizedNames = [];
      const allUserPermissions = [...new Set([...data.user, ...data.role])];
      allUserPermissions.forEach(permission => {
        const normalizedName = this.normalizeLabel(permission.name);
        if (normalizedName != null && !normalizedNames.includes(normalizedName)){
          normalizedNames.push(normalizedName);
        }
      });
      this.normalizedPermissions = this.allnormalizedPermissions;
      this.selectedPermissionNames = normalizedNames;
      this.currentUserNoHeritedPermissions = userPermissionsIds;
      this.permissionsHeritedFromRole = fromRolePermissionsIds;
      this.checkedPermissionsId = [...new Set([...userPermissionsIds, ...fromRolePermissionsIds])];
      this.$nextTick(() => {
        this.$refs['userForm'].clearValidate();
      });
      this.showOnlySelected();
      this.dialogCreateUser = true;
      this.loading = false;
    },
    createUser() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.currentUser.roles = [this.currentUser.role];
          this.userCreating = true;
          userResource
            .store(this.currentUser)
            .then(async(response) => {
              this.$message({
                message: this.$t('user.NewUser') + ' ' + this.currentUser.nom + ' ' + this.currentUser.prenom + ' (' + this.currentUser.email + ') ' + this.$t('user.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentUserId = response.data.id;
              this.confirmPermissions();
              this.resetNewUser();
              this.dialogCreateUser = false;
              this.handleFilter();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.userCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    confirmPermissions() {
      const checkedPermissions = this.checkedPermissionsId.filter((el) => !this.permissionsHeritedFromRole.includes(el));
      userResource.updatePermission(this.currentUserId, { permissions: checkedPermissions }).then(response => {
        this.$message({
          message: this.$t('user.PermissionsUpdated'),
          type: 'success',
          duration: 5 * 1000,
        });
      });
    },
    updateUser() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.userCreating = true;
          userResource.update(this.currentUserId, this.currentUser).then(response => {
            this.confirmPermissions();
            this.resetNewUser();
            this.handleFilter();
            this.$message({
              message: this.$t('user.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateUser = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.userCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewUser() {
      this.currentUser = {
        nom: '',
        prenom: '',
        email: '',
        username: '',
        password: '',
        c_password: '',
        role: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('user.nom'), this.$t('user.prenom'), this.$t('user.email'), this.$t('user.role')];
        const filterVal = ['id', 'nom', 'prenom', 'email', 'roles'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des utilisateurs SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    permissionKeys(permissions) {
      return permissions.map(permssion => permssion.id);
    },
    classifyPermissions(permissions) {
      const all = []; const menu = []; const other = [];
      permissions.forEach(permission => {
        const permissionName = permission.name;
        all.push(permission);
        if (permissionName.startsWith('voir menu')) {
          menu.push(this.normalizeMenuPermission(permission));
        } else {
          other.push(this.normalizePermission(permission));
        }
      });
      return { all, menu, other };
    },

    normalizeMenuPermission(permission) {
      return { id: permission.id, name: this.$options.filters.uppercaseFirst(permission.name.substring(10)), disabled: permission.disabled || false };
    },

    normalizePermission(permission) {
      const disabled = permission.disabled || permission.name === 'gerer permission';
      return { id: permission.id, name: this.$options.filters.uppercaseFirst(permission.name), disabled: disabled };
    },

    confirmPermission() {
      const checkedMenu = this.$refs.menuPermissions.getCheckedKeys();
      const checkedOther = this.$refs.otherPermissions.getCheckedKeys();
      const checkedPermissions = checkedMenu.concat(checkedOther);
      this.dialogPermissionLoading = true;

      userResource.updatePermission(this.currentUserId, { permissions: checkedPermissions }).then(response => {
        this.$message({
          message: this.$t('user.PermissionsUpdated'),
          type: 'success',
          duration: 5 * 1000,
        });
        this.dialogPermissionLoading = false;
        this.dialogPermissionVisible = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
